<template>
  <form ref="form" @submit.prevent="handleValidate">
    <b-row>
      <b-col>
        <basic-input
          label="Mã thực đơn"
          description
          placeholder="Nhập mã thực đơn"
          :value.sync="form.code"
          name="code"
          :required="true"
          :state="validateState('code')"
          :invalidFeedback="errors.first('code')"
          v-validate="'required|alpha_num|max:50'"
          data-vv-as="Mã thực đơn"
          maxlength="50"
          :disabled="isDetail"
        ></basic-input>
      </b-col>
      <b-col>
        <basic-input
          label="Tên thực đơn"
          description
          placeholder="Nhập tên thực đơn"
          :value.sync="form.menuTitle"
          name="menuTitle"
          :required="true"
          :state="validateState('menuTitle')"
          :invalidFeedback="errors.first('menuTitle')"
          v-validate="'required|max:50'"
          data-vv-as="Tên thực đơn"
          maxlength="50"
          :disabled="isDetail"
        ></basic-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <basic-select
          label="Lượng KCAL"
          name="kCal"
          placeholder="--- Chọn lượng kcal cho thức ăn ---"
          :required="true"
          :options="kCals"
          :value.sync="form.kCal"
          v-model="form.kCal"
          :state="validateState('kCal')"
          :invalidFeedback="errors.first('kCal')"
          data-vv-as="Lượng KCAL"
          track-by="value"
          :disabled="!isCreate && !isCopy"
          v-validate="
            `required|${isCopy && validateKcal ? `min_value:${data.kCal}` : ''}`
          "
        />
      </b-col>
      <b-col>
        <basic-select
          label="Số bữa phụ"
          name="snack"
          placeholder="--- Chọn số bữa phụ ---"
          :required="true"
          :options="snacks"
          :value.sync="form.noOfSnackTime"
          :state="validateState('snack')"
          :invalidFeedback="errors.first('snack')"
          data-vv-as="Số bữa phụ"
          track-by="value"
          :disabled="!isCreate && !isCopy"
          v-validate="'required'"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <basic-select
          label="Trạng thái"
          name="status"
          placeholder="--- Chọn trạng thái ---"
          :options="statuses"
          :value.sync="form.status"
          track-by="value"
          disabled
        />
      </b-col>
      <b-col>
        <b-form-group label="Sử dụng gợi ý món ăn thay thế">
          <b-form-checkbox
            id="checkbox-menu"
            v-model="form.allowSugguestionMenu"
            name="checkbox-menu"
            class="menu-checkbox"
            :disabled="isDetail"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!isCreate && !isCopy">
      <b-col>
        <basic-text-area
          label="Chú thích"
          name="note"
          placeholder="Chú thích"
          :value.sync="form.note"
          maxlength="100"
          :state="validateState('note')"
          :invalidFeedback="errors.first('note')"
          v-validate="'max:100'"
          :disabled="isDetail"
        />
      </b-col>
    </b-row>
    <div v-if="isCreate || isCopy" class="d-flex justify-content-end">
      <b-button
        class="btn btn-plain ml-3"
        type="button"
        @click.stop="handleCancel"
      >
        Huỷ
      </b-button>
      <b-button class="btn btn-success ml-3" type="submit">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
        </span>
        Lưu
      </b-button>
    </div>
  </form>
</template>
<script>
import { isNumber } from 'lodash';
export default {
  name: 'FormMenu',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'detail',
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        code: null,
        menuTitle: null,
        kCal: null,
        noOfSnackTime: null,
        status: {
          name: 'Draft',
          value: 2,
        },
        allowSugguestionMenu: false,
        note: null,
      },
      snacks: [
        {
          name: '0',
          value: 0,
        },
        {
          name: '1',
          value: 1,
        },
        {
          name: '2',
          value: 2,
        },
        {
          name: '3',
          value: 3,
        },
      ],
      kCals: [
        {
          name: '1000',
          value: 1000,
        },
        {
          name: '1200',
          value: 1200,
        },
        {
          name: '1400',
          value: 1400,
        },
        {
          name: '1600',
          value: 1600,
        },
        {
          name: '1800',
          value: 1800,
        },
        {
          name: '2000',
          value: 2000,
        },
        {
          name: '2200',
          value: 2200,
        },
        {
          name: '2400',
          value: 2400,
        },
        {
          name: '2600',
          value: 2600,
        },
      ],
      statuses: [
        {
          name: 'Active',
          value: 1,
        },
        {
          name: 'Inactive',
          value: 0,
        },
        {
          name: 'Draft',
          value: 2,
        },
      ],
    };
  },
  computed: {
    isUpdate() {
      return this.type === 'edit' || false;
    },
    isCreate() {
      return this.type === 'create' || false;
    },
    isCopy() {
      return this.type === 'copy' || false;
    },
    showBtnInactive() {
      if (this.form.status && this.form.status.value === 1) return true;
      return false;
    },
    showBtnActive() {
      if (this.form.status && [0, 2].includes(this.form.status.value))
        return true;
      return false;
    },
    validateKcal() {
      if (this.data && this.data.kCal && this.veeFields.kCal?.dirty)
        return true;
      return false;
    },
  },
  watch: {
    data: {
      handler(val) {
        if (!val) return;
        this.setupData();
      },
      deep: true,
    },
  },
  methods: {
    setupData() {
      this.form = { ...this.data };
      this.form.noOfSnackTime = this.snacks.find(
        (el) => el.value === this.data.noOfSnackTime,
      );
      this.form.status = this.statuses.find(
        (el) => el.value === this.data.status,
      );
      if (this.type === 'copy') {
        this.kCals = this.kCals.filter((el) => el.value >= this.data.kCal);
      }
      this.form.kCal = this.kCals.find((el) => el.value === this.data.kCal);
    },
    async submit() {
      const params = {
        code: this.form.code,
        menuTitle: this.form.menuTitle,
        status: isNumber(this.form.status)
          ? this.form.status
          : this.form.status.value,
        kCal: isNumber(this.form.kCal) ? this.form.kCal : this.form.kCal.value,
        noOfSnackTime: isNumber(this.form.noOfSnackTime)
          ? this.form.noOfSnackTime
          : this.form.noOfSnackTime.value,
        allowSugguestionMenu: this.form.allowSugguestionMenu,
        note: this.form.note,
      };
      this.$emit('submit', params);
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit();
        }
        return;
      });
    },
    handleCancel() {
      this.$emit('cancel');
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    resetValidateForm() {
      this.$validator.reset();
    },
  },
};
</script>
<style lang="scss">
.menu-checkbox {
  .custom-control-label::before,
  .custom-control-label::after {
    width: calc(1.5em + 1.3rem + 2px);
    height: calc(1.5em + 1.3rem + 2px);
    top: 0;
  }

  .custom-control-label::before {
    border: 1px solid #e4e6ef;
  }

  .col-form-label {
    padding-bottom: calc(0.45rem + 1px);
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #01645ab3;
    border-color: #01645ab3;
  }
}
</style>
